import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import React from "react";

const ResetPasswordUrlInvalid = ({ url }) => {
  return (
    <div className="flex h-full w-full items-center justify-center bg-backgroundColor py-5">
      <div className="text-center lg:mb-40">
        <h1 className="text-9xl font-bold text-gray-800">404</h1>
        <p className="mb-4 text-2xl font-semibold text-gray-600">Oh no... Something went wrong</p>
        <p className="text-gray-500">This password reset url is either expired or it never worked.</p>
        <p className="mt-6 text-gray-500">
          Return to the{" "}
          <a
            className="inline-flex items-center justify-center gap-x-1 px-1 text-highlightColor"
            href={url}
            title="Return to forgot password screen">
            forgot password <ArrowTopRightOnSquareIcon className="h-4 w-4" />
          </a>{" "}
          page.
        </p>
      </div>
    </div>
  );
};

export default ResetPasswordUrlInvalid;
